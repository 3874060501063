.notification {
  position: absolute !important;
  right: 10px;
  top: 60px;
  /* margin-top: 10px; */
}
.iconSection {
  position: relative;
  width: fit-content;
  display: inline-flex;
}
.notificationBar {
  display: block;
  box-shadow: 2px 3px 17px -3px #8888888c;
  padding: 15px;
  right: 0;
  z-index: 1;
  background: #f4f4f4;
  color: initial;
}
.notification:hover .notificationBar {
  display: block;
}
.lineItmes {
  padding: 5px;
  margin-bottom: 10px;
}
.lineItmes:hover * {
  background: #e6f0fa;
}

.iconBadge {
  background: red;
  width: 15px;
  display: block;
  text-align: center;
  height: 15px;
  border-radius: 50%;
  color: #fff;
  font-size: 9px;
  line-height: 15px;
  position: absolute;
  right: 0;
  bottom: 13px;
  left: 12px;
}