div.showImage {
  position: relative;
  float:left;
  margin:5px;
}
div.showImage:hover img{
  opacity:0.5;
}
div.showImage:hover input {
  display: block;
}
div.showImage input {
  position:absolute;
  display:none;
}
div.showImage .update {
  bottom:0;
  right:0;
}
div.showImage input.delete {
  top:0;
  left:79%;
}
div.showImage:hover .update {
  display: block;
}
div.showImage .update {
  position:absolute;
  display:none;
}