.btnFacebook { 
  width: 190px;
  height:45px;  
  border-radius: 4px;
  background: #093ea7;
  color:white;
  border:0px transparent;  
  text-align: center;
  margin:5px;
  display: inline-block;
}
.linkedinBtn { 
  height:45px;  
  border-radius: 4px;
  background: #007ab7;
  color:white;
  border:0px transparent;  
  text-align: center;
  margin:5px;
  display: inline-block;
}
.btnGoogle { 
  width: 190px;
  height:45px;
  border-radius: 4px;
  background: #db3236;
  color:white;
  border:0px transparent;
  text-align: left;
  margin-right: 0px !important;
}
.btnFacebook:hover {
  cursor: pointer;
}
.linkedinBtn:hover {
  cursor: pointer;
}
.btnGoogle:hover {
  background: #db3236;
  opacity: 0.6;
}

@media only screen and (max-width : 399px) {
  .btnFacebook,.btnGoogle{
    width: 100%
  }
}